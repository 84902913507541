@import "../variables.module.scss";

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: $grey;
  gap: 1em;


  #footer__image {
    height: 2em;
  }

  &__based-in {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }

  &__links {
    display: flex;
    gap: 1em;
  }
}

#rdam-flag {
  width: 2em;
  height: 1.5em;
}


@media screen and (max-width: $breakpoint-mobile) {
  #footer__based-in {
    flex-direction: row-reverse;
  }
}
