@import '../variables.module.scss';

#emailForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-end;
  margin: 2em;

  * {
    padding: 1em 1em;
    border-radius: 0.5em;
    border: 1px solid $blue;
  }
}

.input__error {
  border-color: red;
}

.form__input {
  width: 100%;
  background-color: white;
}
textarea.form__input {
  height: 14em; 
}

.form__submit {
  width: 50%;
}