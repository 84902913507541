@import '../variables.module.scss';

#frontpage__main-container {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 3em;
  overflow: hidden;
}

#frontpage__title {
  font-size: 3em;
  padding-left: 1em;
  color: $grey;
  font-family: 'Recoleta';
}

#frontpage__headshot-wrapper {
  position: relative;
  height: 100%;
  translate: none;
  rotate: none;
  scale: none;
  transform: translate3d(0px, 2.26247px, 0px);
}

#frontpage__headshot {
  position: absolute;
  width: 30%;
  padding-left: 0.5em;

  inset: 0px;
  animation-delay: calc(var(--scroll) * -1s);

  @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    width: 80vw;
  }
  @media (max-width: $breakpoint-mobile) {
    width: 80vw;
  }
}

#frontpage__undertitle-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 32em;

  span {
    font-size: 5em;
    color: $grey;
    cursor: pointer;
  }
  h1 {
    font-size: 3em;
    color: $grey;
    font-family: 'Recoleta';
  }
}
