@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta Regular'), local('Recoleta-Regular'),
    url('./Recoleta-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta Medium'), local('Recoleta-Medium'),
    url('./Recoleta-Medium.ttf') format('truetype');
  font-style: medium;
}

@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta Bold'), local('Recoleta-Bold'),
    url('./Recoleta-Bold.ttf') format('truetype');
  font-style: bold;
}
