$breakpoint-tablet: 768px;
$breakpoint-mobile: 600px; 


$grey: #DEDCDB; 

$green: #135e63;
$blue: #202fcf;
$purple: #5503b2;
$darkRed: #321e23;
$darkBlue: #243e6f;


:export {
  grey: $grey;
  green: $green;
  blue: $blue;
  purple: $purple;
  darkRed: $darkRed;
  darkBlue: $darkBlue;
}