@import '../variables.module.scss';

.scroller {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color: $grey;
  height: 3rem;
  overflow: hidden;
  display: flex;
  white-space: nowrap;

  p {
    animation: scroll_text 20s linear infinite;
    font-family: 'Roboto Mono';
  }
}

@keyframes scroll_text {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
