@import '../variables.module.scss';

.contact__title {
  padding-bottom: 1em;
}

.contact__container {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));

}

.contact__alternative {
  margin: 2em;
}