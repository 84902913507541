@import '../variables.module.scss';

.skills__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  gap: 1em;

  margin-top: 1.5em;

}

.skills__card {
  background-color: #253E6F;
  color: $grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  gap: 1em;
}

.skills__card-image {
  aspect-ratio: 1/1;
  width: 33%;
}

.skills__card-text {
  color: $purple;
}
