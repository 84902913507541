@import '../variables.module.scss';

#header {
  position: sticky;
  display: flex;
  justify-content: space-around;
  z-index: 1;
  top: 0;
  background-color: $grey;
  align-items: center;

  > * {
    padding: 1.5em 0;

  }
  @media (max-width: $breakpoint-mobile) {
    flex-direction: row;
    background-color: $grey;
  }

  h3 {
    text-align: center;
    animation: bgColor 5s infinite linear;
  }

  &__home-link {
    cursor: pointer;
  }
}

.header {
  &__nav-link {
    text-decoration: none;
    cursor: pointer;
    animation: bgColor 5s infinite linear;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 1em 0em;

  *:hover {
    font-weight: bold;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  #header {
    flex-direction: row;
  }
  .header__current-employer {
    display: none;
  }
  
}
