@import './variables.module.scss';

.container {
  max-width: 80em;
  margin-inline: auto;
  padding-inline: 2em;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding-inline: 0;
  }
}

h2 {
  font-family: 'Recoleta';
}
